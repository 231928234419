import React from "react";
//import { Link } from "react-router-dom";
import "./Qna.css";
// import { RegistrationContext } from "../../context/RegistrationContext";
 
const Qna = (props) => {
  // const { registrationPool } = useContext(RegistrationContext);
 
  // const closedButton = (
  //   <a href="">
  //     {props.year} Federated Health NHL Hockey Pool
  //   </a>
  // );
 
  // const openedButton = (
  //   <a href="http://www.officepools.com/invite/classic/m/HGDKZJCE" target="_blank">
  //     {props.year} Federated Health NHL Hockey Pool
  //   </a>
  // );
 
  return (
    <React.Fragment>
      <section className="content-section bg-light text-center" id="qanda">
        <div className="container">
          <div className="content-section-heading">
            <div className="heading"><h1>Frequently Asked Questions</h1></div><br/>
            <div className="qandaWrapper">
              <div className="row">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <button
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#col1"
                      >
                        Where can I get more information?
                      </button>
                    </div>
 
                    <div id="col1" className="collapse show">
                      <div className="card-body">
                        Check out our website for more information:{" "}
                        <a href={"/"}>
                            ITS FedHealth Hockey Playoff Pool
                          </a>
                        <br />
                        <strong style={{ fontSize: "1.5em" }}>or</strong>
                        <br />
                        Contact the Commissioner, Frank at
                        {" "} <a href="mailto:thecommish@fedhealthhockeypool.com" className="text-info"><span id="emailColour">thecommish@fedhealthhockeypool.com </span></a>

                      </div>
                    </div>
                  </div>
                </div>
 
                <div className="col-lg-12">
                  <div className="card">
                    {/* <!-- If we want header and body to be in collapse format, then collapsed should be added to button --> */}
                    <div className="card-header">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#col2"
                      >
                        When do I register?
                      </button>
                    </div>
 
                    {/*  <!-- and collapse must be added to the parent of card-body --> */}
                    <div id="col2" className="collapse">
                      <div className="card-body">
                        <div>
                          {/*   <!-- <p>Register today and be sure to select your team before <span className="text-danger">April 10, 2019
                              @ 12:00 PM</span> (Noon)</p> --> */}
 
                          <p>
                            The regular season ends on April 17, 2025. The playoff start date is expected to be <strong>April 19, 2025</strong>.  
                            Please ensure that your roster is set, and your entry fee is paid by <strong>5:00 PM</strong> on the playoff start date of <strong>April 19, 2025</strong>.
                            A reminder will be sent 3 days before the start date. Good Luck to all participants!  
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
 
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#col3"
                      >
                        How do I register?
                      </button>
                    </div>
 
                    <div id="col3" className="collapse">
                      <div className="card-body">
                        {/* ternary operator to handle which element to render for page redirection */}
                        {/* Link to is used for internal routing to diff components, <a> is for external links */}
                        <div>
                          <p>
                            Click <strong> REGISTER</strong> in the Menu above or click the link here:
                          </p>
                          <a target="_blank" rel="noreferrer" href={props.registrationIsClosed ? props.registrationLink : "https://www.officepools.com/accounts/invite-login?next=/invite/classic/m/HL6GGCRL&it=m&pt=0&pi=HL6GGCRL"}>
                            {props.year} Federated Health Charities NHL Playoff Pool
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
 
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#col4"
                      >
                        Do I have to work for the OPS (Ontario Public Service) to participate?
                      </button>
                    </div>
 
                    <div id="col4" className="collapse">
                      <div className="card-body">
                        <div>
                          <p>
                            You need to be working in the OPS to be eligible to participate.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
 
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#col5"
                      >
                        Is there a cash prize payout?
                      </button>
                    </div>
 
                    <div id="col5" className="collapse">
                      <div className="card-body">
                        <div>
                          <p>
                            There are <strong> NO CASH</strong> prizes.  
                            Prizes are donations received by vendors and other individuals.  
                            Please check out our List of Sponsors on the Home page.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
 
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#col6"
                      >
                        How can I donate a prize or donate to the prize pool?
                      </button>
                    </div>
 
                    <div id="col6" className="collapse">
                      <div className="card-body">
                        <div>
                        <p>
                          Please contact the Commissioner to donate.
                        </p>
                        <p>
                          Frank:{" "}
                          <span className="text-info">
                          <a href="mailto:frank.verbari@ontario.ca" className="text-info"><span id="emailColour">frank.verbari@ontario.ca </span></a>
                          </span>{" "}
                        
                        </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
 
                <div className="col-lg-12">
                  <div className="card">
                    <div className="card-header">
                      <button
                        className="btn btn-link collapsed"
                        data-toggle="collapse"
                        data-target="#col7"
                      >
                        How do I pay?
                      </button>
                    </div>
 
                    <div id="col7" className="collapse">
                      <div className="card-body">
                        <p>
                        While we have returned to the office, we are still working in a hybrid mode so meeting in person may be somewhat challenging.
                        The preferred payment is through e-transfer.<br></br>
                        Remember that <strong>ALL</strong> payments must be received by one of the Commissioners by <strong>5:00 PM April 19, 2025</strong>.
                        </p>
                        <p>Please send e-transfer to:</p>
                        <p>
                          <strong>Frank:{" "}</strong>
                          <span className="text-info">
                            frank.verbari@rogers.com
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
 
export default Qna;