import React from "react";
import "./homepageContent.css";

const HomepageContent = () => {
    return (
        <React.Fragment>
            <section class="content-section bg-light text-center" id="homepageContent">
                <div class="container">
                    <div class="content-section-heading ">
                        <h1>
                            <b>WELCOME TO THE ITS FEDERATED HEALTH HOCKEY PLAYOFF POOL!</b>
                        </h1>
                        <br />

                        <h2>Hello There Poolies…</h2><br></br>

                        <p>Do you know what time it is? If you guessed NHL Playoff Time, then you guessed correctly! And do you know what comes with that…if you guessed the <br></br><strong><span class="annualIts">Annual ITS Federated Health NHL Playoff Hockey Pool</span></strong><br></br>then you guessed correctly again!</p>

                        <p>For those of you who may not know, Commissioner Nino has officially retired from the OPS!  He can now spend more time with family and his other passions Official NHL off-site official in the winter and Umpiring in the summer.  With that, it is bittersweet to see Commish Nino, the founding father of this great pool, step away from co-managing this pool after a 12-year run!  I want to take a moment to express my gratitude for all the years of “blood and sweat” that Commish Nino applied to the success of this pool.</p>

                        <p>This pool is not for the faint of heart… Just ask any veteran hardcore Poolie about the intensity, gamesmanship, and excitement that comes with this pool. Some pour their heart and soul into this epic office battle, while others sweat it out, hoping to gain momentum later in the race.
                        </p>

                        <p>If you're a rookie, let us tell you that hockey pools rarely work out as planned. In a playoff pool, much like the NHL playoffs themselves, it’s a whole new game! It’s a big guessing game—not only do you have to predict which players will score points each game, but more importantly, you have to guess which teams will have a deep playoff run!</p>

                        <p>At the time this was published, it was looking a little more like déjà vu!  For fans of the beloved Toronto Maple Leafs, who have been riding the top of the Atlantic Division most of the season, they are once again hopeful that Year 57 will be the one!  Once again, the Atlantic Division as does the Eastern Conference have a familiar look with much of the same teams emerging as contenders.  In the Western Conference, we see 4 possible Canadian teams emerging with McDavid hopeful that his success in the Four Nations Tournament is a precursor for another run at the Cup!
                        </p>

                        <p>Come on Couch Potato Managers…Get ready to dive into the playoff time warp, where excitement, drama, frustration, and agony await every brave participant!</p>

                        <p>It's time to showcase your unique blend of physicality, grit, speed, and finesse to create your ultimate "ice" masterpiece!</p>

                        <p>Commish Frank has seen it all – from poolies forgetting the basics to hardcore hockey fans getting lost in a sea of statistics like plus-minus, home and road winning percentages, and countless other numbers that might not even matter.
                        </p>

                        <p>
                        Take his advice with a "grain of salt," but he does suggest you don't stress over the endless variables. Just focus on having fun! It's not such a tall order with 16 teams and over 350 players!
                        </p>

                        <p><strong>Pick a few teams to cover your IF-THEN-ELSE bets, and you'll be all set!</strong></p>

                        <p>
                        The weather continues to warm up, so keep an eye on the temperatures in Stanley Cup Country. Grab your thermometer, open your mouth, and say, <strong>“Game On”!</strong>
                        </p>

                        <p>
                        Commish Frank wants you to put on your hockey heads and raise some money for a great cause!
                            
                        </p>
                        <br></br><strong>Time to Play it Forward!</strong>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};

export default HomepageContent;
