import React from "react";
import "./Slideshow.css";

import Slide1 from "../../assets/images/stanley_cup_playoffs_2025.png";
import Slide2 from "../../assets/images/comments-hockypool.png";
import Slide3 from "../../assets/images/thumbnail_image001.jpg";
import Carousel from 'react-bootstrap/Carousel';

const Slideshow = () => {
  return (
    <>
      <Carousel controls={false} variant="dark" id="slideshowCarousel">
        <Carousel.Item id="slideshowCarousel-item">
          <img
            className="d-block w-100"
            src={Slide1}
            alt="First slide"
          />
        </Carousel.Item>
        
        <Carousel.Item id="slideshowCarousel-item">
          <img
            className="d-block w-100"
            src={Slide2}
            alt="Second slide"
          />
        </Carousel.Item>

        <Carousel.Item id="slideshowCarousel-item">
          <img
            className="d-block w-100"
            src={Slide3}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default Slideshow;