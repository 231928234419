import React from 'react';
import './Footer.css';
 
const Footer = (props) => {
    return (
        <React.Fragment>
            <footer id="thefooter" className="text-center text-lg-start bg-body-tertiary text-white font-weight-bold" >
                <section className="">
                    <div className="container text-center text-md-start">
                    <br></br>
                        <div className="row mt-3">
                            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    <i className="fas fa-hockey-puck me-3"></i>PLAYOFF POOL DASHBOARD
                                </h6>
                                <p>
                                Federated Health Charities is one of the two workplace charitable campaigns of the Ontario Public Services (OPS).
                                </p>
                            </div>
                       
                            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">
                                    Useful links
                                </h6>
                                <p>
                                    <a href="/details#top" className="text-reset">Details</a>
                                </p>
                                <p>
                                    <a href="/rulesAndScoring#top" className="text-reset">Rules</a>
                                </p>
                                <p>
                                    <a href="/standings#top" className="text-reset">Standings</a>
                                </p>
                                <p>
                                    <a href="/faq#top" className="text-reset">FAQ</a>
                                </p>
                            </div>
                            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                                <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                                <p>
                                    <i className="fas fa-envelope me-3"></i>
                                    <a href="mailto:frank.verbari@ontario.ca" className="text-info"><span style={{color: 'white'}}>frank.verbari@ontario.ca </span></a>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="text-center p-4" style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
                    © {props.year} ITS FedHealth Hockey Playoff Pool
 
                </div>
            </footer>
        </React.Fragment>
    );
};
 
export default Footer;