import React from "react";
import "./Details.css";
import { Link } from 'react-router-dom';

const Details = (props) => {
  //console.log(props.registrationLink);
  return (
    <>
      <section className="content-section text-center font-weight-bold" id="details">
        <div className="container">
          <div className="content-section-heading">
            <h2>
              Welcome to the {props.year} Federated Health Hockey Playoff Pool!
            </h2>
            <div class="details">
              <p id="intro">
              2025 marks the 13th year of our Playoff Pool! This event has been a tremendous success thanks to your support!
              </p>

              <p>
              Last year was our best year yet. Help us make 2025 even bigger!
              </p>

              <p>
              You might be wondering, why this pool? Or why not a different cash prize pool? 
              </p>

              <p>
              Well, this isn't your typical pool where you use your hockey smarts to get rich, but you do get a chance at some fantastic prizes! Last year, we gave away over 30 prizes to the top teams, and like previous years, we've got tons of prizes up for grabs!
              </p>

              <p>
                What prizes do we have?
              </p>

              <p>
              We're glad you asked! Check out our prize list on the PRIZES Page. Prizes are being added daily!
              </p>

              <Link to="/prizes" className="btn btnWidth btn-outline-primary">
                View Prizes
              </Link>

              <br /><br />
              <h1 id="registrationPrize" className="text-danger">
              ALL THIS FOR ONLY A REGISTRATION FEE OF $25/TEAM
                <span id="puck-tastic">PUCK-TASTIC!</span>
              </h1>
              <h4>Remember, you can't win if you're not in!</h4><br />
              <span className={props.registrationIsClosed ? "" : "disabled"}>
                <a
                  href={props.registrationIsClosed ? props.registrationLink : ""}
                  className={
                    props.registrationIsClosed
                      ? "btn btnWidth btn-outline-danger"
                      : "btn btnWidth btn-outline-danger disabled"
                  }
                >
                  Register
                </a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Details;