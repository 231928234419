import React from "react";
import "./About.css";

const About = () => {
  return (
    <React.Fragment>
      <section className="content-section text-center" id="about">
        <div className="container">
          <div className="content-section-heading font-weight-bold">
            <br />

            {/* <h1 className="mb-5 display-2 about">About Us</h1> */}
            <div className="heading"><h1>About Us</h1></div><br />
            <div class="paragraphs">
              <p>
                <strong>The Annual Hockey Playoff Pool in Support of Charity</strong>
              </p>

              <p>
              Now entering its 13th year, this event has raised nearly $18,000 for our cause. A huge thanks to all the participants; without your involvement, this event wouldn't be such a tremendous success!
              </p>

              <p>
              This event has consistently been a fun and exciting opportunity to earn bragging rights over your colleagues. Each year, participation has grown, with last year setting a record of 130 registered teams.
              </p>


              <p>Truly a remarkable accomplishment!</p>
              <p>Best regards,</p>
              <p>Commissioner Frank Verbari (and Commissioner Alumnus Nino Apostoli)</p>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default About;
